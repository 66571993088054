import React, { useState } from "react"
import AgentCard from "./agent-card"

const AgentList = ({ agentList }) => {

  let initialSortBy = "first_name";

  const getFunctionBySortName = (sortName) => {
    switch (sortName) {
      case "first_name":
        return {
          func: (a, b) => { 
            const aVal = a.firstName ? a.firstName : a.firstName;
            const bVal = b.firstName ? b.firstName : b.firstName;
            return aVal.localeCompare(bVal)
          },
          title: sortName
        }
      case "last_name":
        return {
          func: (a, b) => { 
            const aVal = a.lastName ? a.lastName : a.lastName;
            const bVal = b.lastName ? b.lastName : b.lastName;
            return aVal.localeCompare(bVal);
          },
          title: sortName
        }
      case "default":
      default:
        return {
          func: (a, b) => { 
            const aVal = a.mlsId ? a.mlsId : a.MemberMlsId;
            const bVal = b.mlsId ? b.mlsId : b.MemberMlsId;
            
            return (bVal||"").localeCompare(aVal||"")
          },
          title: sortName
      }
    }
  }

	const [selectedSortBy, setSortBy] = useState(getFunctionBySortName(initialSortBy));

  agentList.sort(selectedSortBy.func);

  const handleSortByInputChange = event => {
    setSortBy(getFunctionBySortName(event.target.value));
  }

  return (
    <>
      <div className="flex flex-row w-full flex-wrap justify-center items-center px-1">
        <div className="sort-filter px-7 py-7" >
          <span>Sort by: </span>
          <select
            id="agentSortBy" 
            onChange = {handleSortByInputChange}
            defaultValue = {initialSortBy}
          >
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
            <option value="default">Default</option>
          </select>
        </div>
      </div>
      <div className="flex flex-row w-full flex-wrap justify-center items-center px-1 py-7">
      {agentList.map((agent, index, allAgents) => {
        let isOddLength = allAgents.length % 2 !== 0;

        let alignClass = ""
        if (isOddLength && index === allAgents.length - 1) {
          alignClass = "lg:items-center"
        } else if (index % 2 === 0) {
          alignClass = "lg:items-end"
        } else {
          alignClass = "lg:items-start"
        }

        return (
          <div
            className={`flex flex-col items-center ${alignClass} w-full lg:w-1/2`}
          >
            <AgentCard agent={agent}></AgentCard>
          </div>
        )
      })}
    </div>
  </>
  )
}

export default AgentList
