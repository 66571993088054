import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AgentList from "../components/agent-list"
// import AgentHeaderImg from "../images/agent-header.jpg"

const People = ({ data, location }) => {
  const allPosts = data.allAgent.nodes;

  return (
    <Layout>
      <Seo title="People" location={location} />
      <div className="agent-list-header">
        <h1 className="h1-header">
          Our Agents
        </h1>
      </div>

      <AgentList agentList={allPosts} />
    </Layout>
  )
}

export default People

export const query = graphql`
  query MyQuery2 {
    allAgent {
      nodes {
        firstName
        lastName
        fullName
        prefferedPhone
        officePhone
        mobilePhone
        email
        city
        address1
        address2
        officeName
        path
        MemberMlsId
        firstName
        lastName
        mlsId
        photo {
          url
        }
        title
        agentBio
      }
    }
  }
`
